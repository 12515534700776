import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
            <h4>Best place to find your next Angular Web Developer</h4>
            <br />
        <ul>
                <li><a href='https://www.SoftwareGladiator.com/' target="_blank"><img src='CodeOfArmsLogo.png' height='20' />&nbsp;Software Gladiator - IT Contractor Services and Software Solutions</a></li>
        </ul>
      </div>
    );
  }
}
